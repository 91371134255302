@media screen and (-webkit-min-device-pixel-ratio: 0) {
	input[type="range"]::-webkit-slider-thumb {
		width: 0px;
		-webkit-appearance: none;
		appearance: none;
		height: 15px;
		cursor: ew-resize;
		background: #00ce71;
		box-shadow: -500px 0 0 500px #00ce71;
	}
}
