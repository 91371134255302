.loader {
	height: 15px;
	display: flex;
	align-items: center;
}

.loader .strokep {
	display: block;
	position: relative;
	background: #00bb67;
	height: 100%;
	width: 4px;
	border-radius: 50px;
	margin: 0 1px;
	animation: animate 1.2s linear infinite;
	animation-play-state: paused;
}
@keyframes animate {
	50% {
		height: 20%;
	}
	100% {
		height: 100%;
	}
}
.strokep:nth-child(1) {
	animation-delay: 0s;
}
.strokep:nth-child(2) {
	animation-delay: 0.3s;
}
.strokep:nth-child(3) {
	animation-delay: 0.6s;
}
.strokep:nth-child(4) {
	animation-delay: 0.9s;
}
.strokep:nth-child(5) {
	animation-delay: 0.6s;
}
.strokep:nth-child(6) {
	animation-delay: 0.3s;
}
.strokep:nth-child(7) {
	animation-delay: 0s;
}

.loader .stroke {
	display: block;
	position: relative;
	background: #00bb67;
	height: 100%;
	width: 4px;
	border-radius: 50px;
	margin: 0 1px;
	animation: animate 1.2s linear infinite;
	animation-play-state: running;
}
@keyframes animate {
	50% {
		height: 20%;
	}
	100% {
		height: 100%;
	}
}
.stroke:nth-child(1) {
	animation-delay: 0s;
}
.stroke:nth-child(2) {
	animation-delay: 0.3s;
}
.stroke:nth-child(3) {
	animation-delay: 0.6s;
}
.stroke:nth-child(4) {
	animation-delay: 0.9s;
}
.stroke:nth-child(5) {
	animation-delay: 0.6s;
}
.stroke:nth-child(6) {
	animation-delay: 0.3s;
}
.stroke:nth-child(7) {
	animation-delay: 0s;
}

/*This is my youtube channel link*/
#yt {
	position: absolute;
	bottom: 20px;
	font-weight: 600;
	text-decoration: none;
	font-size: 20px;
	font-family: sans-serif;
	right: 20px;
	color: #fff;
}
#yt:hover {
	color: red;
}
